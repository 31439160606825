import React from 'react';
import styled from 'styled-components';
import {Button, Table, Modal, message, Popconfirm} from 'antd';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as ApiUtil from 'rev.sdk.js/Utils/ApiUtil';
import qs from 'query-string';
import {useOutlet, getOutlet} from 'reconnect.js';
import DataJson from '../../../data.json';
import {getUserStateLabel, getUserSubstateLabel} from '../../Utils/UserUtil';
import * as AppActions from '../../AppActions';
import AdminSelectProductWidget from '../../Generators/AdminResource/AdminSelectProductWidget';
import AdminSelectUserCustomWidget from '../../Generators/AdminResource/AdminSelectUserCustomWidget';
import {getStaffValue} from '../../Utils/StaffUtil';
import {ModalCustomStyle} from '../../AppContext/customStyledCss';

const pageSize = 20;

const Columns = ({reload}) => {
  return [
    {
      title: '所有紀錄',
      key: '#',
      render: (_, record) => {
        const date = new Date(record.created);
        return (
          <div style={{display: 'flex', width: '100%', alignItems: 'center'}}>
            <div style={{flex: 1}}>
              <div>建立於 {date.toLocaleString('sv')}</div>
              <div>檔案格式： {getEnumName('downloadType', record.type)}</div>
              <div>作業人員：{getStaffValue(record.author, 'name')}</div>
            </div>
            <div>
              <Button
                style={{marginRight: 10}}
                download={record.type === 'csv' ? 'result.csv' : 'result.txt'}
                href={record.link}
                target="_blank">
                下載
              </Button>
              <Popconfirm
                title="確認刪除嗎？"
                okText="確認"
                cancelText="取消"
                onConfirm={async () => {
                  AppActions.setLoading(true);
                  try {
                    await JStorage.deleteDocument('fax_download', {
                      id: record.id,
                    });
                    reload();
                    AppActions.setLoading(false);
                  } catch (err) {
                    console.warn(err);
                    message.error('發生錯誤');
                    AppActions.setLoading(false);
                  }
                }}>
                <Button type="default" danger>
                  刪除
                </Button>
              </Popconfirm>
            </div>
          </div>
        );
      },
    },
  ];
};

const FormSpec = {
  schema: {
    title: '',
    type: 'object',
    required: [],
    properties: {
      downloadType: {
        title: '檔案格式',
        type: 'string',
        enum: ['csv', 'txt'],
        enumNames: ['Email名冊（csv格式）', '傳真名冊（txt格式）'],
      },
      states: {
        type: 'array',
        title: '會員狀態',
        uniqueItems: true,
        items: {
          type: 'integer',
          enum: [1, 2, 3, 4, 5, 6],
          enumNames: ['入會', '退會', '其他', '實習律師', '跨區', '停止'],
        },
      },
      substates: {
        type: 'array',
        title: '會員次狀態',
        uniqueItems: true,
        items: {
          type: 'string',
          enum: ['1', '2', '3', '4', '5', '6', '7'],
          enumNames: [
            '主區會員',
            '準會員',
            '兼區會員',
            '一般會員',
            '特別會員',
            '外國法事務律師',
            '準特別會員',
          ],
        },
      },
      sorting: {
        title: '排序',
        type: 'string',
        enum: ['-created', 'created'],
        enumNames: ['日期新到舊', '日期舊到新'],
      },
    },
  },
  uiSchema: {
    product: {
      'ui:widget': 'admin-select-product-widget',
    },
    owner: {
      'ui:widget': 'admin-select-user-custom-widget',
    },
  },
};

function getEnumName(field, value) {
  const idx = FormSpec.schema.properties[field].enum.indexOf(value);
  if (Array.isArray(FormSpec.schema.properties[field].enumNames)) {
    return FormSpec.schema.properties[field].enumNames[idx];
  }
  return value;
}

function transformQuery(queryParams) {
  const query = {};

  if (queryParams.downloadType === 'txt') {
    query['INFORM_BY'] = 2;
  }

  if (queryParams.states.length > 0) {
    query['state'] = {$in: queryParams.states};
  }

  if (queryParams.substates.length > 0) {
    query['substate'] = {$in: queryParams.substates};
  }

  return {...query};
}

async function generateCsvFile(records) {
  const rows = [{name: '名字', email: '電子郵件地址'}, ...records].map((r) => {
    return [r.name, r.email];
  });

  let csv = '';
  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];
    for (let j = 0; j < row.length; j++) {
      let val = row[j] === null ? '' : row[j]?.toString();
      val = val?.replace(/,/gi, ' ');
      if (j > 0) csv += ',';
      csv += val;
    }
    csv += '\n';
  }

  // for UTF-16
  let cCode,
    bArr = [];
  bArr.push(255, 254);
  for (let i = 0; i < csv.length; ++i) {
    cCode = csv.charCodeAt(i);
    bArr.push(cCode & 0xff);
    bArr.push((cCode / 256) >>> 0);
  }

  const contentType = 'text/csv;charset=UTF-16LE;';
  let blob = new Blob([new Uint8Array(bArr)], {
    type: contentType,
  });

  const resp = await ApiUtil.req(
    `${DataJson.storageHost}/storage/presigned/url?client_id=${
      DataJson.clientId
    }&token=${getOutlet('user').getValue().token}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        acl: 'public-read',
        'Content-Type': contentType,
        key: 'user-csv',
        unique_key: true,
      },
    },
  );

  const {expected, fields, url} = resp;
  const formData = new FormData();

  for (const key in fields) {
    formData.set(key, fields[key]);
  }
  formData.set('file', blob);

  const uploadResp = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  if (uploadResp.status.toString()[0] !== '2') {
    throw uploadResp;
  }

  return expected;
}

async function generateTxtFile(records) {
  const rows = records.map((r, i) => {
    const prefixTwoZero = (i) => `00${i + 1}`.slice(-3);
    return [`${prefixTwoZero(i)}#${r.fax}##${r.name}大律師`];
  });

  let csv = '';
  for (let i = 0; i < rows.length; i++) {
    let row = rows[i];
    for (let j = 0; j < row.length; j++) {
      let val = row[j] === null ? '' : row[j].toString();
      val = val.replace(/t/gi, ' ');
      if (j > 0) csv += '\t';
      csv += val;
    }
    csv += '\n';
  }

  // for UTF-16
  let cCode,
    bArr = [];
  bArr.push(255, 254);
  for (let i = 0; i < csv.length; ++i) {
    cCode = csv.charCodeAt(i);
    bArr.push(cCode & 0xff);
    bArr.push((cCode / 256) >>> 0);
  }

  let contentType = 'text/plain;charset=UTF-16LE;';
  let blob = new Blob([new Uint8Array(bArr)], {
    type: contentType,
  });

  const resp = await ApiUtil.req(
    `${DataJson.storageHost}/storage/presigned/url?client_id=${
      DataJson.clientId
    }&token=${getOutlet('user').getValue().token}`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        acl: 'public-read',
        'Content-Type': contentType,
        key: 'user-txt',
        unique_key: true,
      },
    },
  );

  const {expected, fields, url} = resp;
  const formData = new FormData();

  for (const key in fields) {
    formData.set(key, fields[key]);
  }
  formData.set('file', blob);

  const uploadResp = await fetch(url, {
    method: 'POST',
    body: formData,
  });

  if (uploadResp.status.toString()[0] !== '2') {
    throw uploadResp;
  }

  return expected;
}

export default function AdminFaxExportPage(props) {
  const {token: _token} = qs.parse(props.location.search);
  const [user] = useOutlet('user');
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [fetchResp, setFetchResp] = React.useState(null);
  const [queryModalData, setQueryModalData] = React.useState({
    visible: false,
    data: {
      downloadType: 'csv',
      states: [1],
      substates: ['4', '5', '6'],
      sorting: '-created',
    },
  });
  const queryParams = queryModalData.data;
  const token = _token || (user && !user.tmp && user.token);

  const fetchData = React.useCallback(async () => {
    if (!token) {
      return;
    }

    AppActions.setLoading(true);
    try {
      const fetchResp = await JStorage.fetchDocuments(
        'fax_download',
        {},
        ['-id'],
        paging,
        null,
        {token},
      );

      if (fetchResp.results.length > 0) {
        setFetchResp(fetchResp);
      } else {
        setFetchResp(null);
      }
    } catch (ex) {
      console.warn(ex);
    }
    AppActions.setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, paging, queryParams]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <h1 className="title">傳真管理</h1>
      </div>
      <div className="content">
        <section
          className="query"
          onClick={() => {
            setQueryModalData({data: queryParams, visible: true});
          }}>
          <h3>參數</h3>
          <div>
            檔案格式： {getEnumName('downloadType', queryParams.downloadType)}
          </div>
          <div>
            會員狀態：
            {queryParams.states.map((state, index) => (
              <span>
                {getUserStateLabel(state)}
                {index + 1 === queryParams.states.length ? null : '、'}
              </span>
            ))}
          </div>
          <div>
            會員次狀態：
            {queryParams.substates.map((substate, index) => (
              <span>
                {getUserSubstateLabel(substate)}
                {index + 1 === queryParams.substates.length ? null : '、'}
              </span>
            ))}
          </div>
          <div>排序方式： {getEnumName('sorting', queryParams.sorting)}</div>
        </section>

        <section className="actions">
          <Button
            onClick={async () => {
              try {
                AppActions.setLoading(true);

                if (queryParams.downloadType === 'csv') {
                  const resp = await JStorage.fetchDocuments(
                    'user_profile',
                    transformQuery(queryParams),
                    [queryParams.sorting],
                    null,
                    {name: 1, email: 1, EMAIL2: 1},
                    {token},
                  );

                  if (resp.length === 0) {
                    message.error('查無紀錄');
                    AppActions.setLoading(false);
                    return;
                  }

                  message.info('正在建立檔案');
                  let _resp = [];

                  resp.forEach((r) => {
                    if (r.email) {
                      _resp.push({name: r.name, email: r.email});
                    }

                    if (r.EMAIL2) {
                      if (!r.email) {
                        _resp.push({name: r.name, email: r.EMAIL2});
                      } else {
                        _resp.push({name: `${r.name}#2`, email: r.EMAIL2});
                      }
                    }
                  });

                  _resp = _resp.sort((a, b) =>
                    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
                  );

                  let link = await generateCsvFile(_resp);

                  if (link) {
                    await JStorage.createDocument('fax_download', {
                      type: queryParams.downloadType,
                      link,
                      author: user.username,
                      author_name: user.name,
                    });

                    await AppActions.delay(500);
                    message.info('成功建立檔案！');
                    await fetchData();
                  }
                }

                if (queryParams.downloadType === 'txt') {
                  const resp = await JStorage.fetchDocuments(
                    'user_profile',
                    transformQuery(queryParams),
                    [queryParams.sorting],
                    null,
                    {name: 1, company_current: 1, company_fax: 1},
                    {token},
                  );

                  if (resp.length === 0) {
                    message.error('查無紀錄');
                    AppActions.setLoading(false);
                    return;
                  }

                  const cIds = resp
                    .filter((r) => !!r.company_current)
                    .map((r) => r.company_current);

                  const cs = await JStorage.fetchAllDocuments(
                    'Company',
                    {
                      _id: {$in: cIds.map((id) => ({$oid: id}))},
                    },
                    ['-created'],
                    {COFAX1: 1},
                  );

                  let _resp = [...resp]
                    .filter((r) => {
                      const company = cs.find(
                        (c) => c.id === r.company_current,
                      );

                      if (r.company_fax) {
                        return true;
                      }

                      if (company && company.COFAX1) {
                        return true;
                      }

                      return false;
                    })
                    .map((r) => {
                      const company = cs.find(
                        (c) => c.id === r.company_current,
                      );
                      const fax = r.company_fax || company.COFAX1;

                      return {
                        ...r,
                        fax,
                      };
                    });

                  _resp = _resp.sort((a, b) =>
                    (a.name || '').localeCompare(b.name || '', 'zh-Hant'),
                  );

                  message.info('正在建立檔案');
                  let link = await generateTxtFile(_resp);

                  if (link) {
                    await JStorage.createDocument('fax_download', {
                      type: queryParams.downloadType,
                      link,
                      author: user.username,
                      author_name: user.name,
                    });

                    await AppActions.delay(500);
                    message.info('成功建立檔案！');
                    await fetchData();
                  }
                }
              } catch (err) {
                message.error(`發生錯誤:${err}`);
                console.warn(err);
                AppActions.setLoading(false);
              } finally {
                AppActions.setLoading(false);
              }
            }}>
            建立新檔案
          </Button>
        </section>

        <Table
          columns={Columns({reload: fetchData})}
          dataSource={fetchResp?.results || []}
          rowKey={'id'}
          pagination={{
            size: 'small',
            total: fetchResp?.total || 0,
            showSizeChanger: false,
            showTotal: (total) => `共 ${total} 筆, 每頁 ${pageSize} 筆`,
            current: paging.offset / pageSize + 1,
            pageSize: pageSize,
            position: ['topLeft'],
            onChange: (page, pageSize) => {
              if (pageSize) {
                setPaging({
                  offset: (page - 1) * pageSize,
                  limit: pageSize,
                });
              }
            },
          }}
        />
      </div>

      <QueryFormModal
        data={queryModalData}
        setData={setQueryModalData}
        fetchData={fetchData}
      />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  background-color: #f8f9fa;
  min-height: 100vh;

  & h1.title {
    font-size: 32px;
  }

  & > .content {
    width: min(720px, 100% - 20px);
    margin-inline: auto;
    background-color: white;
    border-radius: 12px;
    border: 1px solid #ddd;
    padding: 20px 10px;

    h1 {
      font-size: 18px;
      margin-bottom: 10px;
    }

    section.query {
      border: 1px dashed var(--adminBorderColor);
      border-radius: 4px;
      padding: 12px;
      cursor: pointer;
      margin-bottom: 10px;

      :hover {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      }
    }

    section.actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-bottom: 10px;
    }
  }
`;

function QueryFormModal(props) {
  const {
    data: {data, visible},
    setData,
  } = props;
  return (
    <Modal
      title={null}
      footer={null}
      bodyStyle={{padding: 0}}
      width={600}
      visible={visible}
      onOk={() => {
        setData({
          data,
          visible: false,
        });
      }}
      onCancel={() => {
        setData({
          data,
          visible: false,
        });
      }}
      destroyOnClose={true}>
      {visible && <QueryModalContent {...props} />}
    </Modal>
  );
}

function QueryModalContent(props) {
  const {
    data: {data: prevData},
    setData,
  } = props;

  const rjsfProps = {
    widgets: {
      'admin-select-product-widget': AdminSelectProductWidget,
      'admin-select-user-custom-widget': AdminSelectUserCustomWidget,
    },
  };

  return (
    <ModalWrapper>
      <h1>參數</h1>

      <GenericForm
        {...FormSpec}
        rjsfProps={rjsfProps}
        instance={{...prevData}}
        onSubmit={async (formData, extValues) => {
          setData({visible: false, data: {...prevData, ...formData}});
        }}
      />
    </ModalWrapper>
  );
}

const ModalWrapper = styled(ModalCustomStyle)`
  padding: 20px;
`;
